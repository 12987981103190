<script>
import {defineComponent} from 'vue'
import InputMixin from "@/components/form/InputMixin.js";

export default defineComponent({
  name: "IconicRadio",
  mixins: [InputMixin],
  inject: {
    radioGroup: {
      default: null
    }
  },
  props: {
    activeValue: {
      type: null,
      default: 1
    },
    buttonType: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  emits: ['update:value'],
  computed: {
    isButton() {
      return !!(this.radioGroup?.injected && this.radioGroup?.isButton) || this.buttonType;
    },
    isActive() {
      if (this.radioGroup?.injected) {
        return this.radioGroup.hasValue(this.activeValue);
      }
      return this.activeValue === this.getVal;
    },
    getVal() {
      if (this.radioGroup?.injected) {
        return this.radioGroup.getValue();
      }
      return this.value;
    },
    getActiveIndex() {
      if (this.radioGroup?.injected) {
        if (!this.getVal) {
          return 0;
        }
        return this.getVal.indexOf(this.activeValue) + 1;
      }
      return 0;
    },
    indexLabelShow() {
      return this.radioGroup?.injected && this.radioGroup.indexLabel;
    }
  },
  methods: {
    toggle() {
      if(this.disabled) {
        return;
      }
      if (this.activeValue === this.getVal) {
        this.updateValue(null);
      } else {
        this.updateValue(this.activeValue);
      }
    },
    async updateValue(val) {
      if (this.radioGroup?.injected) {
        await this.radioGroup.updateValue(val);
      }
      this.$emit('update:value', val);
    }
  }
})
</script>

<template>
  <div
    class="inline-flex gap-2 items-center cursor-pointer relative"
    @click="toggle"
  >
    <div
      v-if="!isButton"
      class="shrink-0 w-5 h-5 border border-stone-700 rounded-full flex items-center justify-center"
    >
      <div
        class="bg-stone-700 rounded-full w-2.5 h-2.5 transitional"
        :class="{'is-active': isActive, 'none-active': !isActive}"
        :data-value="activeValue"
      />
    </div>
    <div
      class="whitespace-nowrap transitional"
      :class="{
        'is-active-button': isActive && isButton,
        'none-active-button': !isActive && isButton,
        'disabled': disabled
      }"
    >
      <slot />
    </div>
    <span
      v-if="indexLabelShow"
      class="tagging shadow -left-10 top-0 absolute"
    >{{ getActiveIndex }} 순위</span>
  </div>
</template>

<style scoped>
.transitional {
  @apply transition-all duration-200 ease-in-out p-2;
}

.is-active-button {
  @apply bg-stone-100 rounded-lg ;
}

.disabled {
  cursor: not-allowed;
  opacity: 0.5;
}

.is-active {
  transform: scale(1);
  opacity: 1;
}

.none-active {
  transform: scale(0);
  opacity: 0;
}

.tagging {
  @apply text-xs h-5 rounded border flex items-center justify-center px-1 bg-stone-200 text-stone-600
}
</style>
