<template>
  <div
    id="talkBtn"
    title="채팅상담하기"
    class="cursor-pointer hidden lg:block"
    style="position: fixed; right: 1rem; bottom : 1rem; z-index: 5"
    @click="openTalk()"
    @mouseenter="()=>{ isOpen = true }"
    @mouseleave="()=>{ isOpen = false }"
  >
    <div class="relative w-[52px] h-[52px] bg-stone-600 flex items-center justify-center rounded-full drop-shadow-lg">
      <svg
        class="ml-1.5 mt-1.5 w-full shrink-0"
        width="36"
        height="36"
        viewBox="0 0 36 36"
        preserveAspectRatio="xMidYMid meet"
        color="#ffffff"
      ><path
        fill-rule="evenodd"
        d="M19.0172 17.5812C17.9609 17.5812 17.5197 16.8186 17.5197 14.9499C17.5197 13.0811 17.9609 12.3186 19.0172 12.3186C20.0734 12.3186 20.5147 13.0811 20.5147 14.9499C20.5147 16.8186 20.0734 17.5812 19.0172 17.5812ZM10.9834 17.5812C9.92716 17.5812 9.48591 16.8186 9.48591 14.9499C9.48591 13.0811 9.92716 12.3186 10.9834 12.3186C12.0397 12.3186 12.4809 13.0811 12.4809 14.9499C12.4809 16.8186 12.0397 17.5812 10.9834 17.5812ZM25.9934 22.9774C25.7309 22.1899 25.7972 21.3324 26.1709 20.5911C27.2372 18.4749 27.7247 16.0186 27.4022 13.4211C26.7047 7.81365 22.1822 3.2799 16.5722 2.59615C8.43717 1.60365 1.60467 8.43615 2.59592 16.5711C3.28092 22.1811 7.81467 26.7049 13.4222 27.4011C16.0197 27.7249 18.4747 27.2361 20.5909 26.1711C21.3334 25.7974 22.1909 25.7299 22.9784 25.9924L25.5247 26.8411C26.3384 27.1124 27.1134 26.3374 26.8422 25.5236L25.9934 22.9774Z"
        fill="currentcolor"
      /></svg>
    </div>
  </div>
</template>

<script setup>
import {computed, ref} from 'vue';
import {useSessionStore} from "@/utils/useSessionStore.js";

const store = useSessionStore()
const isOpen = ref(false);

const openTalk = () => {
  if( window.ChannelIO) {
    window.ChannelIO('showMessenger');
    return;
  }
  const btn = document.querySelector('#talkBtn');
  if(btn){
    btn.remove();
  }

  const w = window;
  if (w.ChannelIO) {
    return (window.console.error || window.console.log || function () {
    })('ChannelIO script included twice.');
  }
  const ch = function () {
    ch.c(arguments);
  };
  ch.q = [];
  ch.c = function (args) {
    ch.q.push(args);
  };
  w.ChannelIO = ch;

  function l() {
    if (w.ChannelIOInitialized) {
      return;
    }
    w.ChannelIOInitialized = true;
    const s = document.createElement('script');
    s.type = 'text/javascript';
    s.async = true;
    s.src = 'https://cdn.channel.io/plugin/ch-plugin-web.js';
    s.charset = 'UTF-8';
    s.onload = ()=>{
      const loader = setInterval(()=>{
        const btn = document.querySelector('[data-ch-testid="launcher"]');
        if(btn){
          btn.click();
          clearInterval(loader);
        }
      }, 1000);

    }
    const x = document.getElementsByTagName('script')[0];
    x.parentNode.insertBefore(s, x);
  }

  if (document.readyState === 'complete') {
    l();
  } else if (window.attachEvent) {
    window.attachEvent('onload', l);
  } else {
    window.addEventListener('DOMContentLoaded', l, false);
    window.addEventListener('load', l, false);
  }

  const channelOption = {
    "pluginKey": "687354d2-5518-4510-8bb4-0c04509b7c3e",
  };
  let user = computed(() => store.getUser);
  if (user.value && user.value.mb_id) {
    channelOption.memberId = user.value.mb_id;
    channelOption.profile = {
      name: user.value.mb_name,
      mobileNumber: user.value.mb_hp,
    }
  }

  // eslint-disable-next-line no-undef
  ChannelIO('boot', channelOption, ()=>{
    // eslint-disable-next-line no-undef
    ChannelIO('showMessenger');
    isOpen.value = true;
  });
}

defineExpose({
  openTalk
})

</script>

<style>
</style>
