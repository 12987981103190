<template>
  <header
    title="header"
    class="w-full bg-stone-100 z-50 print:hidden"
  >
    <div class="flex flex-row justify-between items-center px-8 pt-8">
      <div class="flex flex-col gap-8 justify-between h-full">
        <router-link to="/">
          <img
            src="/images/icons/logo-main-pc.png"
            class="w-[90px]"
          >
        </router-link>
        <div class="flex self-stretch h-full items-center text-sm font-medium text-center text-gray-500">
          <ul class="flex h-full flex-wrap items-end">
            <li
              class="allProduct h-full cursor-pointer"
              @mouseover="allProduct = true"
              @mouseleave="allProduct = false"
            >
              <div
                class="h-full text-center text-stone-700 text-2xl font-normal leading-7 inline-block mr-5 pb-4 border-b-4 rounded-t-lg "
                :class="{ 'border-active': allProduct, allProductBorder: !allProduct}"
              >
                {{ $t('All Products') }}
              </div>
            </li>
            <li
              class="cursor-pointer"
              @mouseover="TSSA = true"
              @mouseleave="TSSA = false"
              @click="()=>goTo({name:'TssaMain'})"
            >
              <div
                class="h-full text-center text-stone-700 text-2xl font-normal leading-7 inline-block mr-5 ml-5 pb-4 border-b-4 rounded-t-lg"
                :class="{ 'border-active': TSSA, allProductBorder: !TSSA}"
              >
                {{ $t('TSSA') }}
              </div>
            </li>
            <li
              class="cursor-pointer"
              @mouseover="DIY = true"
              @mouseleave="DIY = false"
              @click="()=>goTo({name:'Diy'})"
            >
              <div
                class="h-full text-center text-stone-700 text-2xl font-normal leading-7 inline-block mr-5 ml-5 pb-4 border-b-4 rounded-t-lg"
                :class="{ 'border-active': DIY, allProductBorder: !DIY}"
              >
                {{ $t('Contents') }}
              </div>
            </li>
          </ul>
        </div>
      </div>

      <div class="flex flex-col gap-6">
        <div class="justify-center items-center gap-5 inline-flex">
          <nav
            class="justify-start items-center gap-2 flex cursor-pointer"
            @click="()=>router.push({
              name: 'Cart'
            })"
          >
            <img
              src="/images/icons/cart.png"
              class="w-6 h-6"
              alt=""
            >
            <div class="text-center text-stone-700 text-base font-semibold leading-none">
              {{ $t('장바구니') }}
            </div>
          </nav>
          <nav
            class="justify-start items-center gap-2 flex cursor-pointer"
            @click="loginOrMyPage"
          >
            <div class="relative">
              <img
                src="/images/icons/user.png"
                class="w-6 h-6"
                alt=""
              >
              <div
                v-if="notification.getUnreadCount"
                class="w-4 h-4 -right-1.5 -top-1 text-white text-xs bg-red-400 rounded-full justify-center items-center flex absolute"
              >
                {{ notification.getUnreadCount }}
              </div>
            </div>
            <div class="text-center text-stone-700 text-base font-semibold leading-none">
              {{ (sessionStore.authenticated) ? $t('마이페이지') : $t('로그인') }}
            </div>
          </nav>
          <nav
            class="justify-start items-center gap-2 flex cursor-pointer"
            @click="()=>goTo({name:'CustomerCenter'})"
          >
            <img
              src="/images/icons/phone-filled.svg"
              class="w-6 h-6"
              alt=""
            >
            <div class="text-center text-stone-700 text-base font-semibold leading-none">
              {{ $t('고객센터') }}
            </div>
          </nav>
          <div class="justify-start items-center gap-2 flex cursor-pointer">
            <iconic-select
              v-model:value="currency"
              icon="/images/icons/globe.png"
              transparent
              @change="changeLanguage"
            >
              <template
                v-for="(item) in getLanguageList"
                :key="item.value"
              >
                <iconic-option
                  :value="item.value"
                >
                  <span class="text-center text-stone-700 text-base font-semibold leading-none">{{ item.label }}</span>
                </iconic-option>
              </template>
            </iconic-select>
          </div>
        </div>
        <div class="w-full relative mb-2">
          <iconic-input
            v-model:value="keyword"
            class="w-full bg-clear h-full"
            :placeholder="$t('검색어를 입력하세요.')"
            icon="/images/icons/search.svg"
            @submit="()=>{ submitKeyword() }"
          />
        </div>
      </div>
    </div>
    <!-- popover category -->
    <div
      class="absolute left-0 w-full bg-white"
      :class="allProduct ? 'z-[1000]' : 'z-[-999]'"
    >
      <div
        class="flex w-full bg-white border-b border-stone-700 flex-col justify-start items-center opacity-0 transition-opacity duration-300"
        :class="allProduct ? 'opacity-100' : 'opacity-0 pointer-events-none'"
        @mouseover="handleMouseOver"
        @mouseleave="handleMouseLeave"
      >
        <div
          v-if="rootCategories.length > 0"
          class="flex flex-col w-full justify-center items-start gap-2 p-10"
        >
          <div class="justify-center items-center inline-flex">
            <section
              v-for="(group, p) in rootCategories"
              :key="p"
              class="pr-4 pl-4 flex flex-inline cursor-pointer gap-4 transition-all duration-150 items-center"
              @mouseenter="()=>{ selectedGroup = p; }"
            >
              <nav
                :key="p"
                @click="() => goTo(`/shop/category/${group.id}`)"
              >
                {{ __(group?.name, group?.name_en) }}
              </nav>
            </section>
          </div>

          <div class=" w-full flex flex-row justify-between items-start mt-4 pt-8 pl-4 pr-4 border-t border-stone-300 ">
            <div
              v-if="rootCategories[selectedGroup] && rootCategories[selectedGroup]?.descendants[0]?.descendants?.length > 0"
              class="flex gap-12 root-category"
            >
              <nav
                v-for="(submenu, index) in rootCategories[selectedGroup].descendants"
                :key="index"
                class="flex-col justify-start items-start gap-10 inline-flex"
                :class="(submenu.descendants?.length === 0) ? 'no-child-nav':''"
              >
                <div
                  class="flex-col justify-start items-start gap-4 flex"
                >
                  <div
                    class="rounded justify-start items-center gap-2 inline-flex"
                  >
                    <div
                      class="text-center text-stone-700 text-base font-semibold leading-none cursor-pointer"
                      @click="() => goTo(`/shop/category/${submenu.id}`)"
                    >
                      {{ __(submenu?.name, submenu?.name_en) }}
                    </div>
                    <div class="w-4 h-4 relative origin-top-left -rotate-180" />
                  </div>
                  <div
                    class="flex-col justify-start items-start gap-3 flex"
                  >
                    <div
                      v-for="(item, itemIndex) in submenu.descendants"
                      :key="itemIndex"
                      class="text-left text-stone-500 text-base font-normal leading-none cursor-pointer"
                      @click="() => goTo(`/shop/category/${item.id}`)"
                    >
                      {{ __(item?.name, item?.name_en) }}
                    </div>
                  </div>
                </div>
              </nav>
            </div>
            <div
              v-else
              class="flex gap-4 flex-col"
            >
              <div
                class="rounded justify-start items-center gap-2 inline-flex"
              >
                <div
                  class="text-center text-stone-700 text-base font-semibold leading-none cursor-pointer"
                  @click="() => goTo(`/shop/category/${rootCategories[selectedGroup].id}`)"
                >
                  {{ __(rootCategories[selectedGroup]?.name, rootCategories[selectedGroup]?.name_en) }}
                </div>
                <div class="w-4 h-4 relative origin-top-left -rotate-180" />
              </div>
              <div
                class="flex-col justify-start items-start gap-3 flex"
              >
                <div
                  v-for="(item, index) in rootCategories[selectedGroup].descendants"
                  :key="index"
                  class="text-left text-stone-500 text-base font-normal leading-none cursor-pointer"
                  @click="() => goTo(`/shop/category/${item.id}`)"
                >
                  {{ __(item?.name, item?.name_en) }}
                </div>
              </div>
            </div>
            <div
              class="w-48 self-stretch pl-8 border-l border-stone-300 flex-col justify-start items-start gap-10 inline-flex"
            >
              <div class="flex-col justify-start items-start gap-6 flex">
                <div class="rounded justify-start items-center gap-2 inline-flex">
                  <div
                    class="text-center text-stone-700 text-base font-semibold leading-none cursor-pointer"
                    @click="()=>goTo({name: 'ProductNewItem'})"
                  >
                    What’s new
                  </div>
                  <div class="w-4 h-4 relative origin-top-left -rotate-180" />
                </div>
                <div class="rounded justify-start items-center gap-2 inline-flex">
                  <div
                    class="text-center text-stone-700 text-base font-semibold leading-none cursor-pointer"
                    @click="()=>goTo({name:'ContentSelect', params:{id:1}, query:{
                      last:true
                    }})"
                  >
                    Select
                  </div>
                  <div class="w-4 h-4 relative origin-top-left -rotate-180" />
                </div>
              </div>
              <div class="h-24 pr-12 flex-col justify-start items-start gap-4 flex">
                <div class="flex-col justify-start items-start gap-3 flex">
                  <div
                    class="text-center text-stone-700 text-base font-normal leading-none cursor-pointer"
                    @click="()=>goTo({name:'CustomerProductReview'})"
                  >
                    {{ $t("상품후기") }}
                  </div>
                  <div
                    class="text-center text-stone-700 text-base font-normal leading-none cursor-pointer"
                    @click="goTo({name:'CustomerProductInquiry'})"
                  >
                    {{ $t("상품문의") }}
                  </div>
                  <div
                    class="text-center text-stone-700 text-base font-normal leading-none cursor-pointer"
                    @click="()=>goTo({name:'CustomerCenter'})"
                  >
                    {{ $t("주문/배송문의") }}
                  </div>
                  <div
                    class="text-center text-stone-700 text-base font-normal leading-none cursor-pointer"
                    @click="()=>goTo({name:'OfflineStore'})"
                  >
                    {{ $t("오프라인 스토어") }}
                  </div>
                </div>
              </div>
              <div class="h-10 pr-12 flex-col justify-start items-start gap-4 flex">
                <div class="flex-col justify-start items-start gap-3 flex">
                  <div
                    class="text-center text-stone-700 text-base font-normal leading-none cursor-pointer"
                    @click="()=>router.push({
                      name:'CustomerWholesaleApply'
                    })"
                  >
                    {{ $t("도매신청") }}
                  </div>
                  <div class="text-center text-stone-700 text-base font-normal leading-none">
                    {{ $t("딜러모집") }}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </header>
</template>
<script setup>
import {useRouter} from "vue-router";
import {useSessionStore} from "@/utils/useSessionStore.js";
import {computed, onBeforeMount, onServerPrefetch, ref} from "vue";
import {$confirm} from "@/utils/pluginDialog.js";
import axios from "@/utils/axios.js";
import {$t, __} from "@/i18n/useLanguage.js";
import {useNotification} from "@/utils/useNotification.js";

const router = useRouter();

const sessionStore = useSessionStore();

const keyword = ref('');
const submitKeyword = () => {
  if (keyword.value.length === 0) return;
  goTo({
    name: 'ProductSearch',
    query: {
      keyword: keyword.value
    }
  })
  keyword.value = '';
}
const getLanguageList = computed(() => {
  let list;

  if(sessionStore.isKorean) {
    list = [
      {value:'kr', label:'한국어'},
      {value:'en', label:'English'},
    ]
  } else {
    list = [
      {value:'en', label:'English'},
      {value:'kr', label:'한국어'},
    ]
  }

  return list;
})
const notification = useNotification();

async function fetch() {
  await getCategories();
}

onServerPrefetch(async () => {
  await fetch()
})
const allProduct = ref(false);
const TSSA = ref(false);
const DIY = ref(false);
const categories = ref([])

const rootCategories = computed(() => {
  if(!categories.value.length) return [];
  return [
    ...categories.value
]
});
const selectedGroup = ref(0)

onBeforeMount(async () => {
  await getCategories();
  if(sessionStore.authenticated) {
    await notification.get();
  }
})

const currency = computed(() => {
  return sessionStore.getLanguage;
});

async function changeLanguage() {

  try {
    if(sessionStore.isKorean) {
      await $confirm('Do you want to change language and currency?')
      await sessionStore.changeLanguage('en', 'usd')
    } else {
      await $confirm('언어와 통화를 변경하시겠습니까?')
      await sessionStore.changeLanguage('kr', 'krw')
    }

    window.location.reload();
  } catch (e) {
    //currency.value = sessionStore.getLanguage;
  }
}

const handleMouseOver = () => {
  allProduct.value = true;
};

const handleMouseLeave = () => {
  allProduct.value = false;
};

function loginOrMyPage() {
  if (sessionStore.authenticated) {
    goTo('/my-page')
  } else {
    goTo('/login')
  }
}

function goTo(url) {
  router.push(url);
  allProduct.value = false;
}

const getCategories = async () => {
  try {
    const response = await axios.get('/api/evans-cart/categories-service')
    categories.value = response.data.data;
  } catch (e) {
    console.error(e);
  }
}

</script>
<style>
.allProductBorder {
  border-bottom-color: transparent;
}
.root-category:has(.no-child-nav) {
  flex-direction: column;
}
</style>
