<script setup>

import {useRouter} from "vue-router";
import {__} from "@/i18n/useLanguage.js";

const props = defineProps({
  items: {
    type: Array,
    default: () => []
  }
})

const emit = defineEmits(["click", 'moved']);
const router = useRouter()
function clickHandler(item) {
  if(item.descendants && item.descendants.length) {
    emit('click', item)
    return
  }

  router.push({
    name: 'Category',
    params: {
      categoryId: item.id,
    }
  })
  emit('moved', true)
}
</script>

<template>
  <div
    v-if="props.items.length"
    class="w-full flex-col justify-start items-start gap-4 inline-flex"
  >
    <transition-group
      name="custom"
      enter-from-class="opacity-0"
      enter-to-class=" opacity-1"
      leave-from-class=" opacity-1"
      leave-active-class="absolute z-0"
      leave-to-class="opacity-0"
    >
      <nav
        v-for="(item) in props.items"
        :key="item.id"
        class="w-full rounded justify-start items-center gap-2 inline-flex transition-all duration-300"
        @click="()=>{ clickHandler(item) } "
      >
        <div class="text-center text-stone-700 text-base font-normal leading-none cursor-pointer">
          {{ __(item.name, item.name_en) }}
        </div>
        <img
          v-if="item.descendants && item.descendants.length"
          alt=""
          src="/images/icons/chevron.svg"
          class="w-4 h-4 relative origin-top-left"
        >
      </nav>
    </transition-group>
  </div>
</template>

<style scoped>

</style>
